import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { darkBlue3, textColor } from "site-ui/colors";
import styled from "styled-components";
import { TwillChevron } from "site-ui/src/icons/twill-chevron/twillChevron";

interface Props {
  initialValue?: string;
  options: string[];
  onSelect: (v: string) => void;
  placeholder?: string;
}

export function SelectDropdown({
  initialValue,
  options,
  onSelect,
  placeholder = "",
  ...props
}: Props) {
  return (
    <Autocomplete
      defaultValue={initialValue}
      disableClearable
      options={options}
      autoHighlight
      onChange={(event, newValue: string | null) => onSelect(newValue)}
      getOptionLabel={option => option}
      popupIcon={<TwillChevron alt="Toggle options" />}
      renderOption={option => (
        <span data-test-id={`autocomplete-item-${option}`}>{option}</span>
      )}
      renderInput={params => (
        <StyledField
          {...params}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
          }}
          placeholder={placeholder}
        />
      )}
      {...props}
    />
  );
}

const StyledField = styled(TextField)`
  div:first-child {
    background: white;
    color: ${textColor};
    font-weight: 400;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${darkBlue3};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${darkBlue3};
    border-width: 2px;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${darkBlue3};
    }
  }
`;
