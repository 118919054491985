import React from "react";
import chevronIcon from "./twillChevron.svg";

interface Props {
  alt: string;
}

export function TwillChevron({ alt }: Props) {
  return <img src={chevronIcon} alt={alt} />;
}
