import { Box, Grid, Typography } from "@material-ui/core";
import { graphql, navigate } from "gatsby";
import React from "react";
import { darkBlue3 } from "site-ui/colors";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { Section } from "site-ui/src/components/Section";
import styled from "styled-components";
import { SelectDropdown } from "../components/SelectDropdown";
import { useTranslation } from "twill-net-localization";
import officeIcon from "./icons/office.svg";
import formatCountryForUrl from "../utils/formatCountryForUrl";
import { Helmet } from "react-helmet";

type ContactData = {
  country: string;
  offices: {
    number: string;
    address: string;
  }[];
};

interface Props {
  pageContext: {
    countries: string[];
    contactData?: ContactData;
  };
}

function renderWithNewLines(input: string) {
  return (
    <>
      {input.split("\\n").map((line, key) => {
        return (
          <span key={key}>
            {line} <br />
          </span>
        );
      })}
    </>
  );
}

export default ({ pageContext }: Props) => {
  const { contactData: cd, countries } = pageContext;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("contactPage.goToOffice", "Find your local office")}</title>
      </Helmet>
      <PageWrapper variant="striped">
        <StyledSection
          align="center"
          pb={4}
          heading={
            <>
              <StyledIcon src={officeIcon} alt="Office icon" />
              <Typography variant="h1">
                {t(
                  "contactLocalOfficesPage.findYourOffice",
                  "Find your local office"
                )}
              </Typography>
              <Typography variant="h4">
                {t(
                  "contactLocalOfficesPage.findYourOfficeSubtitle",
                  "See information for all offices within a specific country"
                )}
              </Typography>
              <StyledOfficeBox>
                <StyledSelectDropdown
                  data-test-id="autocomplete-country"
                  initialValue={cd ? cd.country : ""}
                  options={countries}
                  placeholder={t(
                    "contactLocalOfficesPage.selectCountryPlaceholder",
                    "Select country"
                  )}
                  onSelect={country => {
                    const formattedCountry = formatCountryForUrl(country);
                    if (cd && country) {
                      navigate(`../${formattedCountry}`);
                    } else if (country) {
                      navigate(`${formattedCountry}`);
                    }
                  }}
                />
              </StyledOfficeBox>
            </>
          }
        ></StyledSection>
        {cd && (
          <StyledResultsBox>
            <Typography variant="h2" align="center" gutterBottom>
              {t("contactLocalOfficesPage.searchResults", "Results for ")}{" "}
              {cd.country}
            </Typography>
            <StyledGrid
              container
              alignItems="stretch"
              justify="center"
              spacing={4}
            >
              {cd.offices.map((office, key) => {
                const { address, number } = office;
                return (
                  <Grid
                    key={key}
                    item
                    data-test-id={`local-office-item`}
                    xs={8}
                    sm={4}
                    xl={2}
                  >
                    <Box
                      minHeight="100%"
                      border={1}
                      borderColor={darkBlue3}
                      borderRadius={4}
                      p={{ xs: 2, md: 4 }}
                    >
                      <Typography variant="h4" gutterBottom>
                        <b>{cd.country}</b>
                      </Typography>
                      <Typography variant="body1">
                        {renderWithNewLines(address)}
                      </Typography>
                      <Typography variant="body1">
                        <b>{renderWithNewLines(number)}</b>
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </StyledGrid>
          </StyledResultsBox>
        )}
      </PageWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query LocalOfficesPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const StyledSection = styled(Section)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 600px;
  }
`;

const StyledIcon = styled.img`
  width: 3rem;
  margin-bottom: 1.25rem;
`;

const StyledOfficeBox = styled.div`
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: center;
  }
  margin-top: 3rem;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 100%;
  max-width: 37rem;
`;

const StyledResultsBox = styled.div`
  background: white;
  padding: 4rem 0 6rem 0;
`;

const StyledGrid = styled(Grid)`
  margin-top: 4rem;
`;
