function formatCountryForUrl(country) {
  let formatted = country;

  // Replace spaces with stripes
  formatted = formatted.replace(/ /g, "-");

  // Replace ampersants with and
  formatted = formatted.replace(/&/g, "and");

  // Replace any stripes that occur more than once
  while (formatted.includes("--")) {
    formatted = formatted.replace(/--/g, "-");
  }

  // Make everything lowercase
  formatted = formatted.toLocaleLowerCase();

  return formatted;
}

module.exports = formatCountryForUrl;
